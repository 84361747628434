const ProblemList = {
    "Subscription Drop Analysis": {
        tableHeaders: [{ Header: 'Date', accessor: 'Date', type: "number" },
        { Header: 'Customer Name', accessor: 'Customer Name', type: "string" },
        { Header: 'Card Category', accessor: 'Card Category', type: "string" },
        { Header: 'Subscription Fee', accessor: 'Subscription Fee', type: "number" },
        { Header: 'Customer Age', accessor: 'Customer Age', type: "number" },
        { Header: 'Income', accessor: 'Income', type: "number" }],
        welcomeTitle: "Subscription Drop Analysis",
        welcomeSecondaryTitle: "Problem Statement",
        welcomeMessage: "Let’s do subscription data analysis and figure out if other cards are gaining popularity, customer preferences are shifting, or the Platinum card’s fee is the culprit.Time to uncover the story behind the numbers!",
        description: "Identify the potential causes of the decline in the Platinum Rewards Card subscriptions by analyzing a one-year dataset containing information on monthly subscriptions across multiple credit card categories, subscription fees, and customer demographics.",
        beforeStarting: "Alright, let’s put your visualization skills to the test! Let’s start with creating a column chart showing the number of users",
        beforeStarting2: "This will help us identify monthly trends and figure out what might be going wrong with Platinum cards. Ready? Let’s dive in!",
        whileBuilding: "First, drag ‘Card Category’ in the column section (top-side block).",
        whileBuilding2: "Great! Now drag ‘Month’ in the Rows section (left-side block)",
        whileBuilding3: "Select ‘Grouped Column Chart’ in the drop down located in top-left corner.",
        onSuccess: "Great job! Look at those bars—do you spot any dips in Platinum cards? This graph holds some important clues.",
        quizQuestion: "Based on the trends you observed, Explain Why the Platinum Rewards Card subscriptions are dropping. What are the most likely factors causing this shift?",
        options: ["There is reduction in Basic Card Users subscriptions in last two months",
            "Dip in the Average income is observed in the last quarter",
            "Subscription fee is on higher side over the sustained period in last 4 months",
            "Older age people dropped subscription in last few months"
        ],
        correctAnswer: "Subscription fee is on higher side over the sustained period in last 4 months",
        correctGuessMessage: "That’s it! The higher subscription fee over the last four months seems to be driving users away. Great job piecing this together!"

    },
    "Ecommerce Repeat Purchase Analysis": {
        tableHeaders: [{ Header: 'Order Date', accessor: 'Order Date', type: "number" },
        { Header: 'Customer ID', accessor: 'Customer ID', type: "" },
        { Header: 'Product Category', accessor: 'Product Category', type: "string" },
        { Header: 'Customer Satisfaction Score', accessor: 'Customer Satisfaction Score', type: "number" },
        { Header: 'Order Frequency', accessor: 'Order Frequency', type: "number" },
        { Header: 'Delivery Time (in Days)', accessor: 'Delivery Time (in Days)', type: "number" },],
        welcomeTitle: "ECommerce Repeat Purchase Analysis",
        welcomeSecondaryTitle: "Problem Statement",
        welcomeMessage: "Let’s unravel the mystery of declining repeat purchases! Could it be dissatisfaction with certain product categories, delivery delays, or even changing customer preferences? Join me as we dive into the data and uncover the factors affecting customer loyalty!",
        description: "Identify the potential causes behind a decline in repeat customer purchases by analyzing a one-year dataset that includes customer orders, product categories, satisfaction scores, and delivery times. Discover actionable insights to drive customer loyalty and retention.",
        beforeStarting: "Let’s start by exploring customer satisfaction across different product categories. Do certain categories bring smiles, or are there hidden frowns?",
        beforeStarting2: "Do certain categories bring smiles, or are there hidden frowns? Let’s find out!",
        whileBuilding: "First, drag the 'Product Category' column into the Rows section.",
        whileBuilding2: "Good job! Now, let’s add some data to analyze. Next, drag 'Customer Satisfaction Score' into the Values section and set it to 'Average'.",
        whileBuilding3: "Select a 'Column Chart' from the visualization options to bring it all together.",
        onSuccess: "Fantastic work! Do you see any categories that stand out, positively or negatively?",
        quizQuestion: "Based on the insights provided, which actions should the company prioritize to address the decline in repeat purchases?",
        options: ["Focus on improving customer satisfaction scores and delivery times for electronics and home appliances",
            "Offer discounts across all categories regardless of satisfaction levels.",
            "Ignore low customer satisfaction scores and focus solely on faster delivery times.",
            "Reduce the number of products in the electronics category."
        ],
        correctAnswer: "Focus on improving customer satisfaction scores and delivery times for electronics and home appliances",
        correctGuessMessage: "That’s absolutely right! Targeting underperforming categories and optimizing delivery times is the key to boosting repeat purchases. Well done!"


    }
}

export default ProblemList;