import { motion } from 'framer-motion';

import React, { useState, useRef } from 'react'
import One from "../assets/img/one.svg"
import Two from "../assets/img/two.svg"
import Three from "../assets/img/three.svg"
import Five from "../assets/img/five.svg"
import Six from "../assets/img/six.svg"
import Seven from "../assets/img/seven.svg"
import Icon from "../assets/img/icon.svg"
import Menu from "../assets/img/menu.svg";
import Profile from "../assets/img/Avatar.png";
import HomeImage from "../assets/img/home_image.png";
import Coins from "../assets/img/Coins.png";
import { useNavigate } from 'react-router-dom'
import Avatar from './Avatar'
import { useDispatch, useSelector } from 'react-redux';
import { changeProblem } from '../store/slices';
import "./GameList.css";

const GameList = () => {
    const dispatch = useDispatch();
    const coinCount = useSelector((state) => state?.user?.coins);
    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } },
    };
    const navigate = useNavigate();
    const [isSpeaking, setIsSpeaking] = useState(false);
    const speechRef = useRef(null);


    const setVoice = () => {
        speechRef.current = new SpeechSynthesisUtterance("Welcome. Let's do Subscription Drop Analysis. Click on the card to start the assignment.");
        speechRef.current.onstart = () => setIsSpeaking(true);
        speechRef.current.onend = () => setIsSpeaking(false);
        window.speechSynthesis.speak(speechRef.current);
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", position: "relative" }}>
            <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ type: "spring", stiffness: 50, damping: 15 }}
                style={{
                    width: "303px",
                    height: "75px",
                    borderRadius: "16px",
                    borderTop: "1px solid rgba(255, 255, 255, 0.08)",
                    backgroundColor: "rgba(26, 29, 33, 0.4)",
                    boxShadow: "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset",
                    position: "absolute",
                    left: 100,
                    top: 40,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    paddingLeft: "20px"
                }}>
                    <img src={Profile} style={{
                        width: "48px",
                        height: "48px",
                        marginRight: "10px"
                    }} />

                    <h4 style={{
                        color: "var(--Neutral-100, #FFF)",
                        fontSize: "16px",
                        fontWeight: 600,
                        letterSpacing: "0.15px"
                    }}>Ryan Lee</h4>
                </div>

                <img src={Menu} style={{
                    width: "48px",
                    height: "48px",
                    paddingRight: "20px"
                }} />
            </motion.div>
            <div style={{
                width: "707px",
                height: "735px",
                borderRadius: "16px",
                padding: "40px",
                paddingTop: "140px",
                textAlign: "center",
                position: "relative"
            }}>
                <img src={HomeImage} style={{
                    width: "200px",
                    height: "100px",
                    position: "absolute",
                    top: 20,
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: "16px",
                    objectFit: "cover"
                }} />
                <h1 style={{
                    fontSize: "28px",
                    fontWeight: 800,
                    lineHeight: "24px",
                    color: "#82DBF7",
                    marginTop: "8px"
                }}>
                    Problem Solving through data
                </h1>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', marginTop: "50px" }}>
                    <motion.div className="card"
                        initial="hidden"
                        animate="visible"
                        variants={cardVariants}
                        transition={{ delay: 1 * 0.2 }}
                        whileHover={{ scale: 1.05 }}
                        onClick={() => {
                            dispatch(changeProblem({ problem: "Credit Card Usage Analysis" }))
                            navigate('/gamified-data-analytics')
                        }}>
                        <img src={One} style={{ width: "35.703px", height: "46px" }} />
                        <h4 style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "left"
                        }}>Credit Card Usage Analysis</h4>
                        <div className="card-footer">
                            <img src={Icon} />
                        </div>
                    </motion.div>

                    <motion.div className="card highlight"
                        initial="hidden"
                        animate="visible"
                        variants={cardVariants}
                        transition={{ delay: 2 * 0.2 }}
                        whileHover={{ scale: 1.05 }}
                        onClick={() => {
                            dispatch(changeProblem({ problem: "Subscription Drop Analysis" }))
                            navigate('/gamified-data-analytics')
                        }}>
                        <img src={Two} style={{ width: "42.3px", height: "46px", marginBottom: "15px" }} />
                        <h4 style={{
                            fontFamily: "16px",
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "left"
                        }}>Subscription Drop Analysis</h4>
                        <div className="card-footer">
                            <img src={Icon} />
                        </div>
                    </motion.div>


                    <motion.div className="card"
                        initial="hidden"
                        animate="visible"
                        variants={cardVariants}
                        transition={{ delay: 3 * 0.2 }}
                        whileHover={{ scale: 1.05 }}
                        onClick={() => {
                            dispatch(changeProblem({ problem: "Ecommerce Repeat Purchase Analysis" }))
                            navigate('/gamified-data-analytics')
                        }}>
                        <img src={Three} className="card-img" />
                        <h4 style={{
                            fontFamily: "16px",
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "left"
                        }}>Ecommerce Repeat Purchase Analysis</h4>
                        <div className="card-footer">
                            <img src={Icon} />
                        </div>
                    </motion.div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', marginTop: "50px" }}>
                    <motion.div className="card"
                        initial="hidden"
                        animate="visible"
                        variants={cardVariants}
                        transition={{ delay: 4 * 0.2 }}
                        whileHover={{ scale: 1.05 }}
                        onClick={() => {
                            dispatch(changeProblem({ problem: "Anomaly Detection in Bank Transactions" }))
                            navigate('/gamified-data-analytics')
                        }}>
                        <img src={Five} className="card-img" />
                        <h4 style={{
                            fontFamily: "16px",
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "left"
                        }}>Anomaly Detection in Bank Transactions</h4>
                        <div className="card-footer">
                            <img src={Icon} />
                        </div>
                    </motion.div>

                    <motion.div className="card"
                        initial="hidden"
                        animate="visible"
                        variants={cardVariants}
                        transition={{ delay: 5 * 0.2 }}
                        whileHover={{ scale: 1.05 }}
                        onClick={() => {
                            dispatch(changeProblem({ problem: "Loan Application Analysis" }))
                            navigate('/gamified-data-analytics')
                        }}>
                        <img src={Six} className="card-img" />
                        <h4 style={{
                            fontFamily: "16px",
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "left"
                        }}>Loan Application Analysis</h4>
                        <div className="card-footer">
                            <img src={Icon} />
                        </div>
                    </motion.div>


                    <motion.div className="card"
                        initial="hidden"
                        animate="visible"
                        variants={cardVariants}
                        transition={{ delay: 6 * 0.2 }}
                        whileHover={{ scale: 1.05 }}
                        onClick={() => {
                            dispatch(changeProblem({ problem: "Covid-19 Insights Analysis" }))
                            navigate('/gamified-data-analytics')
                        }}>
                        <img src={Seven} className="card-img" />
                        <h4 style={{
                            fontFamily: "16px",
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "left"
                        }}>Covid-19 Insights Analysis</h4>
                        <div className="card-footer">
                            <img src={Icon} />
                        </div>
                    </motion.div>
                </div>
            </div >

            <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ type: "spring", stiffness: 50, damping: 15 }} style={{
                    width: "340px",
                    height: "75px",
                    borderRadius: "16px",
                    borderTop: "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.04))",
                    background: "rgba(26, 29, 33, 0.4)",
                    boxShadow:
                        "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    right: 100,
                    top: 40,
                }}>
                {/* {showCoinAnimation && <Lottie
                    animationData={CoinAnimations}
                    loop={true}
                    style={{
                        height: "200px",
                        width: "140px",
                        position: "absolute",
                        left: 0,
                        bottom: "-30px"
                    }}
                />} */}
                <img src={Coins} style={{ width: "108px", height: "108px", position: "absolute", left: 10, top: -40 }} />
                <h3>
                    <motion.span
                        style={{
                            fontSize: "28px",
                            fontWeight: 800,
                            lineHeight: "24px",
                            color: "#fff",
                            marginRight: "10px"
                        }}
                    >
                        {coinCount}
                    </motion.span>
                    <span style={{
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        color: "#8E8C91",

                    }}>Total <br /> Coins Available</span>
                </h3>

                <div
                    style={{
                        position: "absolute",
                        right: "0",
                        background: "#F97403",
                        width: "85px",
                        height: "30px",
                        borderTopLeftRadius: "13px",
                        borderBottomLeftRadius: "13px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <p style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        color: "#1E2118",

                    }}>Redeem
                    </p>
                </div>
            </motion.div>
            <div onClick={setVoice}>
                <Avatar isSpeaking={isSpeaking} onClick={setVoice} />
            </div>
        </div >)
}

export default GameList