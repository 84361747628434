import React from 'react'
import DATALYZE from "../assets/img/DATALYZE.svg"
// import MachineHack from "../assets/img/MH.svg"
import EXL from "../assets/img/exl.jpeg"
import Email from "../assets/img/email.svg"
import Password from "../assets/img/password.svg"
import { useNavigate } from 'react-router-dom'

import "./Login.css"
const Login = () => {
    const navigate = useNavigate();
    return (
        <div className='login-container'>
            <div className='login-form'>
                <div className='login-form-overlay'></div>
                <img src={DATALYZE} ></img>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", marginTop: "15px" }}>
                    {/* <p className='by'>by</p> <img src={MachineHack} className='title'></img> */}
                    <p className='description'>A gamified learning platform for self-paced data analytics mastery</p>
                    <img src={EXL} className='title' style={{ width: "80px", marginLeft: "auto", marginTop: "-120px" }}></img> 
                </div>

                <div className="input-container" style={{ marginBottom: "25px" }}>
                    <input className="email" type="text" placeholder="Enter your email" />
                    <img src={Email} className="email-icon" alt="Email Icon" />
                </div>
                <div className="input-container">
                    <input className="email" type="password" placeholder="Password" />
                    <img src={Password} className="email-icon" alt="Email Icon" />
                </div>

                <div className='submit-btn' onClick={() => {
                    navigate('/gamelist')
                }}>Log in</div>
            </div>
            <div className='login-image-contianer'>

            </div>
        </div >
    )
}

export default Login