import React, { useState } from 'react'
import { motion } from 'framer-motion';
import UploadCsvGif from "../../assets/animations/csv-upload.gif"
import "./styles.css"

const UploadCsv = ({ open, handleClose }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    let slideShowContent = [
        {
            text: "Welcome to the first step of our data journey! In this module, your goal is to load the dataset into the workspace.",
            img: UploadCsvGif
        },
        {
            text: "Think of it as unpacking a treasure chest—you need to open it and see what’s inside before you start analyzing. The dataset is the foundation of everything we’ll do, so ensuring it’s correctly loaded is crucial.",
            img: UploadCsvGif
        },
        {
            text: "You’ll choose your file, preview the data to ensure it’s in the right format, and confirm there are no errors. Once it’s loaded, we’ll be ready to roll!",
            img: UploadCsvGif
        }
    ]

    return open &&
        <div
            className="modal-overlay"
            onClick={() => {
                handleClose();
            }}
        >
            <motion.div
                className="modal-content"
                initial={{ y: '100%', opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '100%', opacity: 0 }}
                transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                    duration: 0.5,
                }}
                onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
            >
                <h2 style={{ marginBottom: "30px" }}>Data Loading</h2>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <p style={{ width: "50%", fontSize: "18px" }}>
                        {slideShowContent?.[activeIndex]?.text}
                    </p>

                    <img className='csv-gif' alt={"csv"} src={slideShowContent?.[activeIndex]?.img} style={{
                        width: "50%",
                        borderRadius: "10px"
                    }} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                    <button
                        className='close-btn'
                        onClick={() => {
                            if (activeIndex === 2) {
                                handleClose();
                            } else {
                                setActiveIndex(activeIndex + 1)
                            }

                        }}
                    >
                        {activeIndex === 2 ? "Start" : "Next"}
                    </button>
                </div>
            </motion.div>
        </div>
}

export default UploadCsv