import React from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DataAnalysis from "./components/DataAnalysis";
import GameList from "./components/GameList";
import Gameplay from "./components/Gameplay";
import Login from "./components/Login";
import PivotTableComponent from "./components/PivotTableComponent";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";

function App() {
  return (
    <DndProvider backend={HTML5Backend}>

      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/gamelist" element={<GameList />} />
            <Route path="/gamified-data-analytics" element={<Gameplay />} />
            <Route path="/analysis" element={<DataAnalysis />} />
            <Route path="/pivot" element={<div className="pivotTableHld"><PivotTableComponent /></div>} />
          </Routes>
        </Router>
      </div>

    </DndProvider>

  );
}

export default App;

