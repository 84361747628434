import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import DataTable from './DataTable';
import Delete from '../assets/img/delete.png';
import data from "../data/subscriptionsData.json"
import PivotTableComponent from "./PivotTableComponent";
import { useSelector } from "react-redux";
import ProblemList from "../data/problems";
import basicCharts from "../data/chartTypes";
import './DataAnalysis.css';

const DataAnalysis = ({
  task,
  tableData,
  setTableData,
  showDeleteToast,
  setShowDuplicateDataToast,
  selectedAction,
  setSelectedAction,
  handleCleanupData,
  showActionCreator,
  showQuiz,
  quizAnswers,
  setQuizAnswers,
  setMessages,
  messages,
  selectedChart,
  setSelectedChart,
  showChartSelector,
  setShowChartSelector,
  setShowAnalysis,
}) => {

  const currentProblem = useSelector((state) => state?.user?.value);
  const problemDetails = ProblemList?.[currentProblem];
  const columns = React.useMemo(() => [
    ...(ProblemList?.[currentProblem]?.tableHeaders || []),
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <div>
          <button onClick={() => handleDeleteRow(row.index)}> <img src={Delete} alt="Delete" /></button>
        </div>
      ),
    }
  ], [currentProblem]);


  const handleAddRow = (value) => {
    setTableData([value, ...tableData]);
  };

  const handleEditRow = (index, value) => {
    const newData = [...tableData];
    newData[index] = value;
    setTableData(newData);
  };

  const handleDeleteRow = (index) => {
    const newData = [...tableData];
    newData.splice(index, 1);
    setTableData(newData);
  };



  const tableInstance = useTable({ columns, data: tableData }, useFilters, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of rows, use page
    prepareRow,
    setFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  return (
    <>
      <div className="data-analysis">
        {(task === "linked" || task === "cleanup" || task === "proceed") && (
          <DataTable
            handleAddRow={handleAddRow}
            setFilter={setFilter}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            handleEditRow={handleEditRow}
            handleDeleteRow={handleDeleteRow}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
            data={data}
            showDeleteToast={showDeleteToast}
            setShowDuplicateDataToast={setShowDuplicateDataToast}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            handleCleanupData={handleCleanupData}
            showActionCreator={showActionCreator}
          />
        )}

        {task === "analyse" && !showQuiz && showChartSelector ?
          <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "start" }}>
            <div style={{ maxWidth: "420px", height: "200px", gap: "16px", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", marginRight: "20px" }}>
              {basicCharts?.map((item) => {
                return (
                  <div style={{
                    width: "200px",
                    height: "56px",
                    background: selectedChart?.name === item?.name ? "#010C12" : "rgba(54, 58, 61, 0.20)",
                    border: selectedChart?.name === item?.name ? "0.5px solid #00FF85" : "0.5px solid #363A3D",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    paddingLeft: "15px"
                  }}
                    onClick={() => {
                      setSelectedChart(item);
                    }}
                  >
                    <p style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      color: "#fff"
                    }}>{item?.name}</p>
                  </div>)
              })}
            </div>
            <div style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "423px",
              borderRadius: "10px",
              backgroundColor: "#FFF",
              padding: "15px"
            }}>
              <img src={selectedChart?.thumbnail} alt={selectedChart?.name} style={{ marginBottom: "40px", }} />
              <h4 style={{
                fontSize: "14px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: 500,
                marginBottom: "10px"
              }}>
                {selectedChart?.name}
              </h4>
              <p style={{
                fontSize: "12px",
                lineHeight: "16px",
                color: "#8E8C91",
                fontWeight: 500
              }}>{selectedChart?.description}</p>
              <button
                style={{
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: 700,
                  lineHeight: "18px",
                  width: "104px",
                  height: "34px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  border: "none",
                  background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                  marginTop: "20px"
                }}
                onClick={() => {
                  setShowChartSelector(false);
                  setTimeout(() => {
                    setShowAnalysis(true);
                  }, 500)
                }}
              >
                Proceed Now
              </button>
            </div>
          </div>
          : task === "analyse" && !showQuiz && !showChartSelector && <PivotTableComponent
            tableData={tableData}
            setMessages={setMessages}
            messages={messages}
            selectedChart={selectedChart}
          />

        }
        {showQuiz && <div className="analysis-header">
          <h4 className="analysis-header-title">{problemDetails?.quizQuestion}</h4>
          <div className="analysis-column-field-container" style={{ marginTop: "15px" }}>
            {problemDetails?.options?.map((option) => {
              return (
                <div className="analysis-column-field-item">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={quizAnswers?.includes(option)}
                      onChange={(e) => {
                        if (e?.target?.checked) {
                          setQuizAnswers((prevAnswers) =>
                            prevAnswers.includes(option) ? prevAnswers : [...prevAnswers, option]
                          );
                        } else {
                          setQuizAnswers((prevAnswers) => prevAnswers.filter((x) => x !== option));

                        }

                      }} />
                    <span className="checkmark"></span>
                    <span className="checkbox-label">{option}</span>
                  </label>
                </div>
              )
            })}
          </div>
        </div>}

      </div>





    </>
  );
};

export default DataAnalysis;



