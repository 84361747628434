import React, { useState } from 'react'
import { motion } from 'framer-motion';
import DataAnalysisGif from "../../assets/animations/pookie.gif"

import "./styles.css"

const AnalysisModal = ({ open, handleClose }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    let slideShowContent = [
        {
            text: "This is where the magic happens! Your clean and transformed dataset is ready for analysis.",
            img: DataAnalysisGif
        },
        {
            text: "In this module, you’ll dig deep into the data to identify patterns, trends, and insights. It’s like solving a mystery—you’ll uncover the story hidden in the data.",
            img: DataAnalysisGif
        },
        {
            text: "Whether it’s identifying declining trends, demographic patterns, or pricing effects, your goal is to extract meaningful information.",
            img: DataAnalysisGif
        },
        {
            text: "To begin with, try finding out count of monthly subscribers for each card type.",
            img: DataAnalysisGif
        }
    ];


    return open &&
        <div
            className="modal-overlay"
            onClick={() => {
                handleClose();
            }}
        >
            <motion.div
                className="modal-content"
                initial={{ y: '100%', opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '100%', opacity: 0 }}
                transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                    duration: 0.5,
                }}
                onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
            >
                <h2 style={{ marginBottom: "30px" }}>Data Analysis</h2>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <p style={{ width: "50%", fontSize: "18px", marginRight: "10px" }}>
                        {slideShowContent?.[activeIndex]?.text}
                    </p>

                    <img className='csv-gif' alt={"csv"} src={slideShowContent?.[activeIndex]?.img} style={{
                        width: "50%",
                        borderRadius: "10px"
                    }} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                    <button
                        className='close-btn'
                        onClick={() => {
                            if (activeIndex === 3) {
                                handleClose();
                            } else {
                                setActiveIndex(activeIndex + 1)
                            }

                        }}
                    >
                        {activeIndex === 3 ? "Start" : "Next"}
                    </button>
                </div>
            </motion.div>
        </div>
}

export default AnalysisModal;