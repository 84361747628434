import React, { useState } from 'react'
import { motion } from 'framer-motion';
import DataAnalysisGif from "../../assets/animations/dataAnalysis.gif"
import "./styles.css"

const DataCleanup = ({ open, handleClose }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    let slideShowContent = [
        {
            text: "Now that the data is loaded, it’s time to tidy things up! Raw data often comes with issues like duplicates, missing values, or inconsistent formats—like trying to read a messy diary.",
            img: DataAnalysisGif
        },
        {
            text: "In this module, you’ll clean the dataset to ensure it’s accurate, consistent, and ready for analysis.",
            img: DataAnalysisGif
        },
        {
            text: "This step is essential because clean data leads to reliable results. By the end of this module, your dataset will shine like a polished gem!",
            img: DataAnalysisGif
        }
    ];


    return open &&
        <div
            className="modal-overlay"
            onClick={() => {
                handleClose();
            }}
        >
            <motion.div
                className="modal-content"
                initial={{ y: '100%', opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '100%', opacity: 0 }}
                transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                    duration: 0.5,
                }}
                onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
            >
                <h2 style={{ marginBottom: "30px" }}>Data Cleaning</h2>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <p style={{ width: "50%", fontSize: "18px", marginRight: "10px" }}>
                        {slideShowContent?.[activeIndex]?.text}
                    </p>

                    <img className='csv-gif' alt={"csv"} src={slideShowContent?.[activeIndex]?.img} style={{
                        width: "50%",
                        borderRadius: "10px"
                    }} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                    <button
                        className='close-btn'
                        onClick={() => {
                            if (activeIndex === 2) {
                                handleClose();
                            } else {
                                setActiveIndex(activeIndex + 1)
                            }

                        }}
                    >
                        {activeIndex === 2 ? "Start" : "Next"}
                    </button>
                </div>
            </motion.div>
        </div>
}

export default DataCleanup;