import React from 'react'
import { motion } from 'framer-motion';
import MissingValueTreatment from '../MissingValueTreatment';
import "./styles.css"

const MissingDataFixingModal = ({
    missingValueList,
    allData,
    selectedMissingValue,
    setSelectedMissingValue,
    onSubmit,
    open
}) => {

    return (
        open && <div
            className="modal-overlay"
        >
            <motion.div
                className="modal-content-new"
                initial={{ y: '100%', opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '100%', opacity: 0 }}
                transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                    duration: 0.5,
                }}
                onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
            >
                <h2 style={{ marginBottom: "30px" }}>Data Analysis</h2>
                <MissingValueTreatment
                    missingValueList={missingValueList}
                    allData={allData}
                    selectedMissingValue={selectedMissingValue}
                    setSelectedMissingValue={setSelectedMissingValue}
                    onSubmit={onSubmit}
                />
            </motion.div>
        </div>
    )
}

export default MissingDataFixingModal