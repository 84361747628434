import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import Profile from "../assets/img/Avatar.png";
import Menu from "../assets/img/menu.svg";
import Vector from "../assets/img/progress.svg";
import Coins from "../assets/img/Coins.png";
import VectorConfirmed from "../assets/img/progressSuccess.svg";
import ExclamationIcon from "../assets/img/exclamation.svg";
import ChatIcon from "../assets/img/chat.svg";
import TickIcon from "../assets/img/check-circle.svg"
import data from "../assets/img/data.svg";
import link from "../assets/img/link.svg";
import csv from "../assets/img/csv.svg";
import Arrow from "../assets/img/arrow.svg";
import successIcon from "../assets/img/success.svg";
import Lottie from "lottie-react";
import LoadingLottie from "../assets/animations/B7nKG0EeXW.json"
import CoinAnimations from "../assets/animations/eZPu8zOqAR.json"
import { motion } from "framer-motion";
import 'react-tippy/dist/tippy.css';
import "./Gameplay.css"
import DataAnalysis from "./DataAnalysis";
import Avatar from './Avatar'
import Joyride from 'react-joyride';
import CreditCard from "../data/subscriptionsData.json"
import Papa from 'papaparse';
import UploadCsv from "./Tutorials/UploadCsv";
import DataCleanup from "./Tutorials/DataCleanup";
import AnalysisModal from "./Tutorials/AnalysisModal"
import MissingDataFixingModal from "./Tutorials/MissingDataFixingModal";
import TreatmentModal from "./Tutorials/TreatmentModal";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useDispatch, useSelector } from "react-redux";
import ProblemList from "../data/problems";
import { updateCoinsCount } from "../store/slices";
import { useNavigate } from "react-router-dom";
const tasks = [{ title: "Data Loading", status: "welcome" }, { title: "Data Cleaning", status: "cleanup" }, { title: "Data Analysis", status: "analyse" },];

const Gameplay = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const currentProblem = useSelector((state) => state?.user?.value);
    const coinCount = useSelector((state) => state?.user?.coins);
    let problemDetails = ProblemList?.[currentProblem]
    const [task, setTask] = useState("welcome");
    const [showQuiz, setShowQuiz] = useState(false);
    const [tableData, setTableData] = useState(CreditCard);
    const [showCoinAnimation, setShowCoinAnimation] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [successIndex, setSuccessIndex] = useState(0)
    const speechRef = useRef(null);
    const containerRef = useRef();
    const [expand, setExpand] = useState(true);
    const [showDuplicateDataToast, setShowDuplicateDataToast] = useState(false);
    const [showDeleteToast, setShowDeleteToast] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [duplicateDataList, setDuplicateDataList] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [userInput, setUserInput] = useState("")
    const [success, setSuccess] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [selectedAction, setSelectedAction] = useState("none");
    //Tutorial animations
    const [showLinkCsvModal, setShowLinkCsvModal] = useState(false);
    const [showDataCleanupModal, setShowDataCleanuModal] = useState(false);
    const [selectedMissingValue, setSelectedMissingValue] = useState({})
    const [cleanupPerformed, setCleanupPerformed] = useState({
        duplicates: false,
        missing_data: false
    });
    const [quizAnswers, setQuizAnswers] = useState([]);
    const [showNoDuplicateModal, setShowNoDuplicateModal] = useState(false);
    const [showNoEmptyModal, setShowNoEmptyModal] = useState(false);
    const [showActionCreator, setShowActionCreator] = useState(false);
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [showMissingDataModal, setShowMissingDataModal] = useState(false);
    const [speechEnabled, setSpeechEnabled] = useState(true);
    const [showTreatmentModal, setShowTreatmentModal] = useState(false);
    let [messages, setMessages] = useState([
        "Hey there!",
    ]);
    const [selectedChart, setSelectedChart] = useState({
        name: "Column Chart",
        thumbnail: `https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/datalyze/bar-chart.svg`,
        value: "Grouped Column Chart",
        description: "It displays a categorical dimension and related amounts. Each bar represents a category, width is proportional to the quantitative dimension."
    });
    const [showChartSelector, setShowChartSelector] = useState(false);

    useEffect(() => {
        if (messages.length === 1) {
            setMessages([...messages, problemDetails?.welcomeMessage]);
        }
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, [messages]);
    
    useEffect(() => {
        if(showChartSelector) {
            setMessages(prevMessages => [
                ...prevMessages,
                problemDetails?.beforeStarting
            ]);        
            setTimeout(() => {
                setMessages(prevMessages => [
                    ...prevMessages,
                    problemDetails?.beforeStarting2
                ]);
            }, 7000);
        }
    }, [showChartSelector]);

    useEffect(() => {
        const playCoinSound = () => {
            const audio = new Audio("/collectcoin.wav");
            audio.play();
        };
        if (coinCount > 0) {
            setTimeout(() => {
                playCoinSound();
            }, 500);
        }
    }, [coinCount]);

    useEffect(() => {
        if (showQuiz) {
            setMessages([...messages, "Alright, let’s put your visualization skills to the test! Your mission is to create a column graph showing the number of users with each card type across all months."])
            setTimeout(() => {
                setMessages([...messages, "This will help us identify monthly trends and figure out what might be going wrong with Platinum cards.Ready ? Let’s dive in!"])
            }, 4500)
        }
    }, [showQuiz])

    const askGPT = async (text) => {
        const prompt = `${text} give response in 12 words`;

        const data = {
            model: 'gpt-3.5-turbo',
            messages: [
                { role: 'system', content: 'You are a helpful assistant.' },
                { role: 'user', content: prompt },
            ],
            temperature: 0.2,
        };

        try {
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer sk - wem0W1fFksGJWTdowDmbT3BlbkFJjE1DhZIMFBZOZ97Pc0lB`,
                    },
                }
            );
            setMessages([...messages, response.data.choices?.[0]?.message?.content]);
            return response.data.choices[0].message.content;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (task === "proceed") {
            dispatch(updateCoinsCount({ increment: 10 }))
            setShowCoinAnimation(true)
            setTimeout(() => {
                setShowCoinAnimation(false)
            }, 2000)
        }

    }, [task]);

    useEffect(() => {
        if (speechEnabled && messages?.length > 0) {
            const currentMessage = messages[messages.length - 1];
            if (currentMessage) {
                // Cancel any ongoing speech before starting a new one
                window.speechSynthesis.cancel();

                // Create a new SpeechSynthesisUtterance instance
                speechRef.current = new SpeechSynthesisUtterance(currentMessage);
                speechRef.current.onstart = () => setIsSpeaking(true);
                speechRef.current.onend = () => setIsSpeaking(false);

                // Start speaking
                window.speechSynthesis.speak(speechRef.current);
            }
        }
    }, [messages?.length, speechEnabled]);

    const handleToggle = () => {
        if (isSpeaking) {
            // Stop speech
            window.speechSynthesis.cancel();
            setIsSpeaking(false);
        } else if (speechEnabled) {
            // Restart speaking the current message
            const currentMessage = messages[messages.length - 1];
            if (currentMessage) {
                speechRef.current = new SpeechSynthesisUtterance(currentMessage);
                speechRef.current.onstart = () => setIsSpeaking(true);
                speechRef.current.onend = () => setIsSpeaking(false);
                window.speechSynthesis.speak(speechRef.current);
            }
        }

        // Toggle the speechEnabled state
        setSpeechEnabled((prev) => !prev);
    };
    // const setVoice = () => {
    //     speechRef.current = new SpeechSynthesisUtterance(messages[messages.length - 1]);
    //     speechRef.current.onstart = () => setIsSpeaking(true);
    //     speechRef.current.onend = () => setIsSpeaking(false);
    //     window.speechSynthesis.speak(speechRef.current);
    // };

    const steps = [
        {
            target: '.progress-tooltip',
            content: 'Check the list of Tasks and Progress',
        },
        {
            target: '.coins-tooltip',
            content: 'Based on completion of tasks, you will earn coins',
        },
        {
            target: '.chat-tooltip',
            content: 'Keep an eye on this chat for instructions',
        },
        {
            target: '.avatar-tooltip',
            content: 'Click on avatar to hear out the instructions',
        },
    ];

    const findDuplicates = (array) => {
        const duplicates = [];
        const occurrences = new Map();
        // Count occurrences of each object
        array.forEach((obj) => {
            const objString = JSON.stringify(obj); // Convert object to a string
            occurrences.set(objString, (occurrences.get(objString) || 0) + 1);
        });
        // Add objects that appear more than once, including originals
        array.forEach((obj) => {
            const objString = JSON.stringify(obj);
            if (occurrences.get(objString) > 1) {
                duplicates.push(obj);
            }
        });

        return duplicates;
    };

    const getUniqueObjects = () => {
        const uniqueArray = [];
        const seen = new Set();
        tableData?.forEach((obj) => {
            const objString = JSON.stringify(obj);
            if (!seen.has(objString)) {
                seen.add(objString);
                uniqueArray.push(obj);
            }
        });

        return uniqueArray;
    };



    const handleCleanupData = (type) => {
        if (type === "duplicates") {
            if (cleanupPerformed?.duplicates) {
                setMessages([...messages, `All clear—no duplicates here.This dataset is already on the right track!`])
                setShowNoDuplicateModal(true);
                setTimeout(() => {
                    setShowNoDuplicateModal(false);
                }, 3000)
                setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                    "duplicates": true
                }))
            } else {
                const duplicateData = findDuplicates(tableData);
                if (duplicateData?.length > 0) {
                    setShowDuplicateDataToast(true);
                    setShowDeleteToast(true)
                    setDuplicateDataList(duplicateData);
                    setMessages([...messages, `Aha! Found ${duplicateData?.length} duplicates. Good catch!`])
                } else {
                    setShowNoDuplicateModal(true);
                    setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                        "duplicates": true
                    }))
                    setMessages([...messages, `All clear—no duplicates here.This dataset is already on the right track!`])
                    setTimeout(() => {
                        setShowNoDuplicateModal(false);
                    }, 3000)
                }
            }
        } else if (type === "missing_data") {
            if (cleanupPerformed?.missing_data) {
                setMessages([...messages, "Looks like the data is complete."])
                setShowNoEmptyModal(true)
                setTimeout(() => {
                    setShowNoEmptyModal(false);
                }, 3000)
                setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                    "missing_data": true
                }))
                return;
            }

            setShowDeleteToast(false);
            const incompleteData = tableData?.filter((x) => {
                return Object.values(x).some(value => value === null || value === undefined || value === "");
            });


            if (!incompleteData?.length) {
                setMessages([...messages, "Looks like the data is complete."])
                setShowNoEmptyModal(true)
                setTimeout(() => {
                    setShowNoEmptyModal(false);
                }, 3000)
                setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                    "missing_data": true
                }))
            } else {
                setTimeout(() => {
                    setShowTreatmentModal(true);
                }, 1000);
                setDuplicateDataList(incompleteData);
                setMessages([...messages, `Oh no, looks like some values are missing! Don’t worry—we’ll fix them in no time.`])

            }
        } else {
            console.log("reset")
        }
    }

    const fillMissingCategoryValue = (key) => {
        if (selectedMissingValue?.[key] === "mode") {
            const valueCounts = {};
            // Count occurrences of each value for the given key
            for (const obj of tableData) {
                const value = obj[key];
                if (value !== undefined) {
                    valueCounts[value] = (valueCounts[value] || 0) + 1;
                }
            }

            // Find the most occurring value
            let maxCount = 0;
            let mostOccurringValue = null;

            for (const [value, count] of Object.entries(valueCounts)) {
                if (count > maxCount) {
                    maxCount = count;
                    mostOccurringValue = value;
                }
            }

            return mostOccurringValue;
        }
    }

    const fillMissingNumericValue = (key) => {
        if (selectedMissingValue?.[key] === "mean") {
            let sum = 0;
            let count = 0;
            for (const obj of tableData) {
                const value = parseFloat(obj[key]); // Convert the value to a number
                if (!isNaN(value)) {
                    sum += value;
                    count++;
                }
            }

            return count > 0 ? (sum / count).toFixed(2) : null;
        } else if (selectedMissingValue?.[key] === "median") {
            const numericValues = [];
            for (const obj of tableData) {
                const value = parseFloat(obj[key]); // Convert the value to a number
                if (!isNaN(value)) {
                    numericValues.push(value);
                }
            }

            if (numericValues.length === 0) return null;
            numericValues.sort((a, b) => a - b);
            const mid = Math.floor(numericValues.length / 2);
            if (numericValues.length % 2 === 0) {
                return ((numericValues[mid - 1] + numericValues[mid]) / 2).toFixed(2); // Average of middle two numbers
            } else {
                return (numericValues[mid]).toFixed(2); // Middle value
            }
        }
    }



    const handleFixingMissingData = () => {
        setMessages([...messages, "Impressive! You’ve patched up all the gaps. No blanks holding us back now."])
        let finalFixedList = tableData?.slice();
        const CompleteDataSet = tableData?.filter((x) => {
            return Object.values(x).every(value => Boolean(value))
        });

        finalFixedList = duplicateDataList?.map((set) => {
            let finalObj = Object.assign({}, set);
            let hasMoreThanOneFieldMissing = Object.keys(finalObj)?.filter((x) => !finalObj?.[x])?.length > 1 ? true : false;
            if (hasMoreThanOneFieldMissing) {
                let listOfAllTheMissingFields = Object.keys(finalObj)?.filter((x) => !finalObj?.[x]);
                listOfAllTheMissingFields.forEach((missingField) => {
                    let dataType = problemDetails?.tableHeaders?.filter((header) => header?.accessor === missingField)?.[0]?.type;
                    finalObj = Object.assign({}, finalObj, {
                        [missingField]: dataType === "string" ? fillMissingCategoryValue(missingField) : fillMissingNumericValue(missingField)
                    })
                })
            } else {
                Object.keys(set)?.forEach((key) => {
                    if (!set?.[key]) {
                        let dataType = problemDetails?.tableHeaders?.filter((header) => header?.accessor === key)?.[0]?.type;
                        if (dataType === "string") {
                            finalObj = Object.assign({}, set, {
                                [key]: fillMissingCategoryValue(key)
                            })
                        } else {
                            finalObj = Object.assign({}, set, {
                                [key]: Number(fillMissingNumericValue(key))
                            })
                        }
                    }
                })
            }

            return finalObj;
        })

        let allFieldsToBeDeleted = [];
        Object.keys(selectedMissingValue)?.forEach((key) => {
            if (selectedMissingValue?.[key] === "delete") {
                allFieldsToBeDeleted.push(key);
            }
        });

        if (allFieldsToBeDeleted?.length > 0) {
            allFieldsToBeDeleted?.forEach((keyToBeDeleted) => {
                finalFixedList = finalFixedList?.filter((x) => x?.[keyToBeDeleted])
            })
        }

        setTableData([...(finalFixedList?.length > 0 ? finalFixedList : []), ...(CompleteDataSet?.length > 0 ? CompleteDataSet : [])]);
        setDuplicateDataList([]);
        setCleanupPerformed(Object.assign({}, cleanupPerformed, {
            "missing_data": true
        }))

        dispatch(updateCoinsCount({ increment: 10 }))
        setShowCoinAnimation(true)

        setTimeout(() => {
            setShowCoinAnimation(false)
        }, 2000)
        setShowMissingDataModal(false)
    }

    const handleGoBackToProgress = (status) => {
        setTask(status)
    }

    const handleLinkData = async () => {
        setTask("linkingData");
        setExpand(false)
        dispatch(updateCoinsCount({ increment: 5 }))
        setShowCoinAnimation(true)

        try {
            let data;
            if (currentProblem === "Subscription Drop Analysis") {
                const module = await import("../data/subscriptionsData.json");
                data = module.default;
            } else if (currentProblem === "Ecommerce Repeat Purchase Analysis") {
                const module = await import("../data/Ecommerce.json");
                data = module.default;

            }

            setTableData(data);
            setTimeout(() => {
                setTask("linked");
                setMessages([...messages, "Ah, there it is! The data has arrived safely. Let’s have a quick look to see what we’re working with."])
            }, 2000);
            setTimeout(() => {
                setShowCoinAnimation(false)
                const duplicateData = findDuplicates(tableData);
                if (duplicateData?.length > 0) {
                    setShowDuplicateDataToast(true);
                    setTask("cleanup")
                }
                setSuccessIndex(1)
            }, 2000)

        } catch (error) {
            console.error("Error loading JSON file:", error);
        }
    }


    return (
        <div
            className="wrapper"
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                height: "100%",
                padding: "40px",
            }}
        >
            <Joyride steps={steps} run={true} continuous={true} showSkipButton={true} />

            <motion.div
                animate={{
                    width: expand ? "303px" : "80px",
                    opacity: expand ? 1 : 0.8,
                }}
                whileHover={{
                    scale: 1.02, // Slight scale on hover
                }}
                transition={{
                    type: "spring",
                    stiffness: 150, // Adjust for spring speed
                    damping: 14,    // Adjust for smoothness
                }}
                style={{
                    height: "735px",
                    borderRadius: "15px",
                    borderTop: "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08))",
                    background: "var(--Glass-Modal, rgba(26, 29, 33, 0.46))",
                    boxShadow:
                        "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset",
                    display: "flex",
                    flexDirection: "column",
                    padding: "17px",
                    marginRight: "30px",
                }}
            >
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <motion.div
                        initial={{ opacity: 0, x: -30 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={Profile}
                            style={{ width: "48px", height: "48px", marginRight: "20px" }}
                        />
                        {expand &&
                            <motion.p
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}
                            >
                                Ryan Lee
                            </motion.p>}
                    </motion.div>
                    {expand && <motion.div
                        initial={{ opacity: 0, x: -30 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.6, duration: 0.5 }}
                    >
                        <img src={Menu} />
                    </motion.div>}
                </motion.div>

                {
                    expand ? <div className="progress-tooltip" style={{ marginTop: "20px" }}>
                        <p
                            style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                color: "var(--Noble-Black-400, #686B6E)",
                                letterSpacing: "0.15px",
                                marginBottom: "20px",
                            }}
                        >
                            TASKS
                        </p>

                        {tasks.map((item, index) => (
                            <motion.div
                                key={task}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{
                                    delay: index * 0.2,
                                    duration: 0.5,
                                    ease: "easeOut",
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "15px",
                                    width: "256px",
                                    height: "44px",
                                    borderRadius: "8px",
                                    background: "linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0))",
                                    marginBottom: "10px",
                                }}
                            >
                                <img src={index + 1 <= successIndex ? VectorConfirmed : Vector} style={{ width: "18px", height: "18px", marginRight: "10px" }} />
                                <p style={{ fontSize: "12px", fontWeight: 600, color: "#E8E9E9", letterSpacing: "0.15px" }}>
                                    {item?.title}
                                </p>
                            </motion.div>
                        ))}

                    </div>
                        : <div className="progress-tooltip" style={{ marginTop: "20px", background: "none" }}>
                            {tasks.map((item, index) => (
                                <div
                                    key={index} // Use index as key
                                    id={`tooltip-${index}`} // Unique ID for each tooltip
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "15px",
                                        height: "44px",
                                        borderRadius: "8px",
                                        marginBottom: "10px",
                                    }}
                                    onClick={() => {
                                        if (index + 1 <= successIndex) {
                                            handleGoBackToProgress(item?.status)
                                        }
                                    }}
                                >
                                    <img
                                        src={index + 1 <= successIndex ? VectorConfirmed : Vector}
                                        style={{ width: "18px", height: "18px", marginRight: "10px" }}
                                        alt={`Step ${index + 1}`}
                                    />
                                    <Tooltip
                                        anchorId={`tooltip-${index}`} // Match the ID of this element
                                        content={item?.title || `Task ${index + 1}`} // Tooltip content
                                        place="top"
                                        offset={10}
                                        className="react-tooltip"
                                        style={{ fontSize: "12px", zIndex: 999 }}
                                    />
                                </div>
                            ))}
                        </div>}
            </motion.div >


            <motion.div
                animate={{
                    width: expand ? "657px" : "1440px",
                }}
                transition={{
                    duration: 0.4, // Adjust for smoothness
                    ease: [0.25, 0.8, 0.25, 1]
                }}
                style={{
                    height: "735px",
                    borderRadius: "16px",
                    borderTop: "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08))",
                    background: "var(--Glass-Modal, rgba(26, 29, 33, 0.96))",
                    boxShadow:
                        "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset",
                    display: "flex",
                    flexDirection: "column",
                    padding: "17px",
                    position: "relative",

                }}>

                {task === "welcome" &&
                    <>

                        <div style={{ display: "flex", flexDirection: "column", height: "300px", width: "100%", justifyContent: "center", alignItems: "center" }}>
                            <motion.h1
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, ease: "easeOut" }}
                                style={{
                                    fontSize: "28px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    color: "var(--Neutral-100, #FFF)",
                                }}
                            >

                                {problemDetails?.welcomeTitle}
                            </motion.h1>
                            <motion.h1
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
                                style={{
                                    fontSize: "28px",
                                    fontWeight: 800,
                                    lineHeight: "24px",
                                    color: "#82DBF7",
                                    marginTop: "8px",
                                }}
                            >
                                {problemDetails?.welcomeSecondaryTitle}
                            </motion.h1>

                            <motion.p
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
                                style={{
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    color: "#8E8C91",
                                    marginTop: "8px",
                                }}
                            >
                                Identify the potential causes of the decline in the Platinum Rewards Card subscriptions by analyzing a one-year dataset containing information on monthly subscriptions across multiple credit card categories, subscription fees, and customer demographics.
                            </motion.p>
                        </div>

                        <div style={{
                            position: "absolute",
                            bottom: "10%",
                            left: 0,
                            width: "100%",
                        }}>
                            <img src={data} style={{ paddingLeft: "40px", paddingBottom: "20px" }}></img>
                        </div>
                    </>
                }

                {task === "linkingData" &&
                    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
                        <Lottie
                            animationData={LoadingLottie}
                            loop={true}
                            style={{
                                height: "200px",
                                width: "200px",
                                position: "absolute",
                                top: 200,
                                left: 360,
                                margin: "0 auto"
                            }}
                        />
                        <h1 style={{
                            fontSize: "28px",
                            fontWeight: 500,
                            lineHeight: "24px",
                            color: "var(--Neutral-100, #FFF)"
                        }}>
                            We are linking your data
                        </h1>
                        <p style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            lineHeight: "24px",
                            color: "#8E8C91",
                            marginTop: "8px"
                        }}>
                            This may take some time
                        </p>


                    </div>}
                {(task === "linked" || task === "analyse" || task === "cleanup" || task === "proceed" || task === "summary") &&
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: task === "linked" ? "center" : "", maxHeight: "700px", overflow: "scroll", padding: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "5px", marginTop: task === "linked" ? "0px" : "10px", width: "100%" }}>
                            <motion.h2
                                style={{
                                    fontSize: (task === "linked" || task === "cleanup" || task === "proceed") ? "28px" : "24px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    color: "var(--Neutral-100, #FFF)",
                                }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.5, duration: 1 }}
                            >
                                {(task === "linked" || task === "proceed" || task === "cleanup") && <motion.img
                                    src={successIcon}
                                    style={{ marginRight: "15px" }}
                                    initial={{ opacity: 0, x: -50 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 1 }}
                                />}
                                {task === "linked" ? "Preview" : (task === "cleanup" && showDuplicateDataToast) ? "Preview" : task === "proceed" ? "Proceed with Summarizing data" : task === "summary" ? "Generated Summary of Subscription Analysis" : !showQuiz && "Choose a Chart"}
                            </motion.h2>

                            {task === "analyse" && !showQuiz && !showChartSelector && <div style={{ display: "flex", flexDirection: "row" }}>
                                <div
                                    style={{
                                        width: "34px",
                                        height: "34px",
                                        backgroundColor: "#363A3D",
                                        borderRadius: "5px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "#fff",
                                        fontSize: "18px",
                                        marginRight: "6px"
                                    }}
                                    onClick={() => {
                                        setShowChartSelector(true);
                                    }}
                                >
                                    {"<"}
                                </div>

                            </div>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <MissingDataFixingModal
                                missingValueList={duplicateDataList}
                                allData={tableData}
                                selectedMissingValue={selectedMissingValue}
                                setSelectedMissingValue={setSelectedMissingValue}
                                onSubmit={handleFixingMissingData}
                                open={showMissingDataModal}
                            />

                            <DataAnalysis
                                task={task}
                                setShowCoinAnimation={setShowCoinAnimation}
                                setSuccessIndex={setSuccessIndex}
                                tableData={duplicateDataList?.length > 0 ? duplicateDataList : tableData}
                                setTableData={setTableData}
                                showDeleteToast={showDeleteToast}
                                setShowDuplicateDataToast={setShowDuplicateDataToast}
                                success={success}
                                setSuccess={setSuccess}
                                showConfetti={showConfetti}
                                setShowConfetti={setShowConfetti}
                                selectedAction={selectedAction}
                                setSelectedAction={setSelectedAction}
                                handleCleanupData={handleCleanupData}
                                showActionCreator={showActionCreator}
                                showQuiz={showQuiz}
                                setShowQuiz={setShowQuiz}
                                quizAnswers={quizAnswers}
                                setQuizAnswers={setQuizAnswers}
                                setMessages={setMessages}
                                messages={messages}
                                selectedChart={selectedChart}
                                setSelectedChart={setSelectedChart}
                                showChartSelector={showChartSelector}
                                setShowChartSelector={setShowChartSelector}
                                setShowAnalysis={setShowAnalysis}
                            />
                        </div>

                    </div>
                }
                <div style={{ marginTop: "20px", display: "flex", justifyContent: "end", position: "absolute", width: "100%", bottom: -40 }}>
                    {messages?.length <= 2 ? <div style={{
                        position: "absolute",
                        bottom: 50,
                        left: 0,
                        fontSize: "15.529px",
                        fontWeight: 700,
                        padding: "15px",
                        width: "100%",
                        height: "61px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                        }}>
                            <motion.div
                                style={{
                                    width: "181.176px",
                                    height: "44px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#000",
                                    borderRadius: "12px",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                }}
                                animate={{
                                    background: [
                                        "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                        "linear-gradient(91deg, #00E5FF 7.38%, #00FF85 96.85%)",
                                        "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                    ],
                                }}
                                transition={{
                                    duration: 2, // Total time for one cycle
                                    ease: "linear",
                                    repeat: Infinity, // Loop the animation infinitely
                                }}
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.3 },
                                }}
                                onClick={() => {
                                    setMessages([
                                        ...messages,
                                        "First, load the dataset that includes monthly subscription numbers, customer age groups, income ranges, and the subscription fees for each card category",
                                    ]);

                                    setTimeout(() => {
                                        setShowLinkCsvModal(true);
                                    }, 500)
                                }}
                            >
                                Proceed
                            </motion.div>

                        </div>
                    </div> : task === "welcome" ? <div style={{
                        position: "absolute",
                        bottom: 50,
                        left: 0,
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "15px",
                        width: "100%",
                        height: "61px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <motion.div
                            style={{
                                width: "120px",
                                height: "36px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#000",
                                borderRadius: "12px",
                                cursor: "pointer",
                                background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)"
                            }}
                            whileHover={{
                                scale: 1.05,
                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                                transition: { duration: 0.3 },
                            }}
                            onClick={() => {
                                handleLinkData()
                            }}
                        >
                            Link Data
                            <img src={link} style={{ marginLeft: "8px" }} />
                        </motion.div>

                        <p style={{ color: "#fff", marginLeft: "7px", marginRight: "7px" }}>OR</p>
                        <motion.button
                            style={{
                                width: "120px",
                                height: "36px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#000",
                                borderRadius: "12px",
                                marginRight: "10px",
                                cursor: "pointer",
                                background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                border: "none"
                            }}
                            whileHover={{
                                scale: 1.05,
                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                                transition: { duration: 0.3 },
                            }}
                            onClick={() => document.getElementById('csvUpload').click()}
                        >
                            Upload CSV
                            <img src={csv} style={{ marginLeft: "8px" }} />
                            <input
                                id="csvUpload"
                                type="file"
                                accept=".csv"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    const file = e.target.files[0];

                                    if (file) {
                                        Papa.parse(file, {
                                            header: true,
                                            complete: (results) => {
                                                setTableData(results.data);
                                            },
                                            error: (error) => console.error(error),
                                        });
                                    }

                                    setShowDataCleanuModal(false);
                                    setTask("linkingData");
                                    setExpand(false)
                                    setTimeout(() => {
                                        setTask("linked");
                                        setMessages([...messages, "Ah, there it is! The data has arrived safely. Let’s have a quick look to see what we’re working with."])
                                    }, 2000);

                                    dispatch(updateCoinsCount({ increment: 5 }))
                                    setShowCoinAnimation(true)

                                    setTimeout(() => {
                                        setShowCoinAnimation(false)
                                        const duplicateData = findDuplicates(tableData);
                                        if (duplicateData?.length > 0) {
                                            setShowDuplicateDataToast(true);
                                            setTask("cleanup")
                                        }
                                        setSuccessIndex(1)
                                    }, 2000)
                                }}
                            />
                        </motion.button>
                    </div> : task === "proceed" ? <div style={{
                        position: "absolute",
                        bottom: 45,
                        left: 0,
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "15px",
                        width: "100%",
                        height: "61px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <motion.div
                            style={{
                                width: "150px",
                                height: "36px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#000",
                                borderRadius: "12px",
                                marginRight: "10px",
                                cursor: "pointer",
                                background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)"
                            }}
                            whileHover={{
                                scale: 1.05,
                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                                transition: { duration: 0.3 },
                            }}
                            onClick={() => {
                                setTask("summary");
                                dispatch(updateCoinsCount({ increment: 10 }))
                                setShowCoinAnimation(true)
                                setTimeout(() => {
                                    setShowCoinAnimation(false)
                                    setMessages([...messages, "Let's analyze the data based on the Generated Summary"])
                                    // setMessages([messages?.filter((x) => x !== "I need your help to understand what’s happening. We have data from the last twelve months, covering subscriptions across different credit card categories, monthly figures, customer demographics, and subscription fees. I’d like to know if other cards are growing in popularity and if there’s a shift in customer preferences or if the subscription fee is discouraging people from choosing the Platinum Rewards Card. Let’s dive in and see what insights we can find!"), "Drag and drop the dataset to the data analysis tool to start analyzing the data"])
                                }, 2000);
                                // setExpand(true)
                            }}
                        >
                            Summarize Data
                            <img src={link} style={{ marginLeft: "8px" }} />
                        </motion.div>
                    </div>
                        :
                        task === "summary" ? < div style={{
                            position: "absolute",
                            bottom: 45,
                            left: 0,
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "15px",
                            width: "100%",
                            height: "61px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <motion.div
                                style={{
                                    width: "150px",
                                    height: "36px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#000",
                                    borderRadius: "12px",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)"
                                }}
                                whileHover={{
                                    scale: 1.05,
                                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                                    transition: { duration: 0.3 },
                                }}
                                onClick={() => {
                                    setTask("analyse");
                                    setTimeout(() => {
                                        setShowTooltip(true);
                                    }, 10000);
                                    dispatch(updateCoinsCount({ increment: 5 }))
                                    setShowCoinAnimation(true)
                                    setTimeout(() => {
                                        setShowCoinAnimation(false)
                                        setMessages([...messages, "Select the columns to start analyzing the data"])
                                    }, 2000);
                                    setSuccessIndex(3)
                                }}
                            >
                                Analyze
                                <img src={link} style={{ marginLeft: "8px" }} />
                            </motion.div>
                        </div> : null}
                    {(task === "cleanup" || task === "proceed" || task === "analyse") && !showChartSelector &&
                        <div style={{
                            position: "absolute",
                            bottom: 50,
                            left: 0,
                            fontSize: "15.529px",
                            fontWeight: 700,
                            padding: "15px",
                            width: "100%",
                            height: "61px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <button
                                style={{
                                    width: "104.55px",
                                    height: "34px",
                                    padding: "6.8px 12.75px",
                                    borderRadius: "10px",
                                    border: "none",
                                    background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                    cursor: "pointer",
                                    color: "#000",
                                    fontSize: "15.529px",
                                    fontWeight: 700,
                                }}
                                onClick={() => {
                                    if (task === "analyse" && !showQuiz) {
                                        setShowQuiz(true);
                                        setSuccessIndex(3);
                                    } else if (task === "analyse" && showQuiz) {
                                        if (quizAnswers?.includes(problemDetails?.correctAnswer)) {
                                            setSuccessIndex(4);
                                            setMessages([...messages, problemDetails?.correctGuessMessage])

                                            dispatch(updateCoinsCount({ increment: 10 }))
                                            setShowCoinAnimation(true)

                                            setTimeout(() => {
                                                setShowCoinAnimation(false)
                                            }, 2000)

                                            setTimeout(() => {
                                                navigate('/gamelist');
                                            }, 10000)
                                        } else {
                                            setMessages([...messages, "Hmm, not quite. Looks like there’s more to uncover in the data. Take a step back and dig a little deeper into those trends. You’ve got this!"])
                                        }

                                    } else {
                                        if (cleanupPerformed?.duplicates && cleanupPerformed?.missing_data) {
                                            setShowChartSelector(true);
                                            setTask("analyse");
                                            // setTimeout(() => {
                                            //     setShowAnalysis(true);
                                            // }, 500)
                                            setSuccessIndex(2);
                                        } else {
                                            setShowDataCleanuModal(true);
                                        }
                                    }
                                }}
                            >
                                {showQuiz ? "Submit" : "Proceed"}
                            </button>
                        </div>
                    }
                </div>
            </motion.div >

            <div style={{ display: 'flex', flexDirection: "column", marginLeft: "25px" }}>
                <div className="coins-tooltip" style={{
                    width: "340px",
                    height: "75px",
                    borderRadius: "16px",
                    borderTop: "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.04))",
                    background: "rgba(26, 29, 33, 0.4)",
                    boxShadow:
                        "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative"
                }}>
                    {showCoinAnimation ? <Lottie
                        animationData={CoinAnimations}
                        loop={true}
                        style={{
                            height: "200px",
                            width: "140px",
                            position: "absolute",
                            left: 0,
                            bottom: "-30px"
                        }}
                    /> : <img src={Coins} style={{ width: "108px", height: "108px", position: "absolute", left: 10, top: -40 }} />}
                    <h3>
                        <motion.span
                            style={{
                                fontSize: "28px",
                                fontWeight: 800,
                                lineHeight: "24px",
                                color: "#fff",
                                marginRight: "10px"
                            }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, count: [0, coinCount] }}
                            transition={{ duration: 1, ease: "easeOut", times: [0, 1] }}
                        >
                            {coinCount}
                        </motion.span>
                        <span style={{
                            fontSize: "15px",
                            fontWeight: 600,
                            lineHeight: "24px",
                            color: "#8E8C91",

                        }}>Total <br /> Coins Available</span>
                    </h3>

                    <div
                        style={{
                            position: "absolute",
                            right: "0",
                            background: "#F97403",
                            width: "85px",
                            height: "30px",
                            borderTopLeftRadius: "13px",
                            borderBottomLeftRadius: "13px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <p style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "24px",
                            color: "#1E2118",

                        }}>Redeem
                        </p>
                    </div>
                </div>


                <div className="chat-tooltip" style={{
                    width: "340px",
                    height: "75px",
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                    borderTop:
                        "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08))",
                    background:
                        "var(--Glass-Fill, linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0.00) 100%))",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    marginTop: "15px"
                }}>

                </div>

                <div style={{
                    width: "340px",
                    height: "490px",
                    borderRadius: "6px 6px 16px 16px",
                    borderTop: "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08))",
                    background: "var(--Glass-Modal, rgba(26, 29, 33, 0.96))",
                    boxShadow:
                        "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "start",
                    position: "relative",
                    marginTop: "-3px",
                    padding: "10px",

                }}>

                    <div style={{ display: "flex", flexDirection: "column", height: "100%", position: "relative" }}>
                        <div style={{ display: "flex", flexDirection: "column", height: "400px", overflowY: "auto" }} ref={containerRef}>
                            {messages?.map((item, index) => {
                                const isLastMessage = index === messages.length - 1 && !showLinkCsvModal && !showDataCleanupModal && !showAnalysis;
                                return (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{
                                            duration: 0.6,
                                            ease: "easeOut",
                                            delay: index * 0.2,
                                        }}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "59px",
                                                height: "24px",
                                                border: "1px solid rgba(255, 255, 255, 0.08)",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                background: "var(--Glass-Stroke, rgba(255, 255, 255, 0.08))",
                                                borderTopLeftRadius: "3px",
                                                borderTopRightRadius: "15px",
                                            }}
                                        >
                                            <p style={{ fontSize: "12px", fontWeight: 500, color: "#FFF" }}>Sensei</p>
                                        </div>
                                        <div>
                                            <motion.p
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: 500,
                                                    color: "#FFF",
                                                    border: "1px solid #363A3D",
                                                    borderRadius: "5px",
                                                    padding: "15px",
                                                    background: "#020D13",
                                                    boxShadow: isLastMessage
                                                        ? "0 0 10px rgba(0, 255, 255, 0.8), 0 0 20px rgba(0, 255, 255, 0.6)"
                                                        : "none", // Glowing effect for the last message
                                                }}
                                                initial={isLastMessage ? { scale: 0.9, opacity: 0.7 } : { opacity: 0 }}
                                                animate={isLastMessage ? { scale: [0.95, 1, 0.95] } : { scale: [1], opacity: 1 }}
                                                transition={
                                                    isLastMessage
                                                        ? {
                                                            duration: 0.8,
                                                            ease: "easeInOut",
                                                            repeat: Infinity, // Pulsating effect
                                                            repeatType: "reverse",
                                                        }
                                                        : { duration: 0.4, ease: "easeOut" }
                                                }
                                            >
                                                {item}
                                            </motion.p>
                                        </div>
                                    </motion.div>
                                )
                            })}
                        </div>



                    </div>

                    <div style={{
                        width: "340px",
                        height: "56px",
                        borderRadius: "16px",
                        borderTop: "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08))",
                        background: "var(--Glass-Modal, rgba(26, 29, 33, 0.96))",
                        boxShadow: "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "20px",
                        paddingRight: "10px"
                    }}>
                        <input
                            placeholder="Write your message..."
                            onFocus={() => {
                                if (showTooltip) {
                                    setShowTooltip(false);
                                }
                            }}
                            value={userInput}
                            onChange={(e) => {
                                setUserInput(e?.target?.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setMessages([...messages, userInput]);
                                    setUserInput("");
                                    setSuccess(true);
                                    setShowConfetti(true);
                                    setTimeout(() => {
                                        setSuccess(false)
                                    }, 3000)
                                    askGPT(userInput);
                                }
                            }}
                            style={{
                                width: "271px",
                                padding: "9.791px 11.749px",
                                gap: "0.979px",
                                borderRadius: "3.916px",
                                background: "rgba(255, 255, 255, 0.06)",
                                border: "none",
                                color: "#B8B8B8",
                                fontSize: "13px",
                                fontWeight: 500
                            }} />
                        <div style={{
                            width: "28px",
                            height: "28px",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                        }}>
                            <img src={ChatIcon} />
                        </div>
                    </div>
                    {showTooltip &&
                        <div style={{
                            position: "absolute",
                            bottom: 0,
                            left: "-75%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start"
                        }}>
                            <div style={{
                                width: "246px",
                                height: "122px",
                                borderRadius: "6.061px",
                                background: "#282A2D",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                padding: "10px"
                            }}>
                                <p style={{
                                    fontSize: "12px",
                                    color: "#F5F5F5"
                                }}>Based on the trends you observed, summarize key insights.</p>
                            </div>
                            <img src={Arrow} />
                        </div>}

                </div>
                {/* <Tooltip title="Click on avatar to hear out the instructions" position="top" trigger="mouseenter"> */}
                <div className="avatar-tooltip" onClick={handleToggle}>
                    <Avatar isSpeaking={isSpeaking} />
                </div>
                {/* </Tooltip> */}
            </div>
            {
                showNoDuplicateModal && <motion.div
                    className="no-duplicate-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    <img src={ExclamationIcon} style={{ marginRight: "5px" }} />
                    No Duplicates found

                </motion.div>
            }
            {
                showNoEmptyModal && <motion.div
                    className="no-duplicate-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    <img src={ExclamationIcon} style={{ marginRight: "5px" }} />
                    No Missing Values found

                </motion.div>
            }
            {
                showDeleteToast && <motion.div
                    className="duplicate-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    Delete duplicate data
                    <button className="action-btn" onClick={() => {
                        setShowDeleteToast(false);
                        const uniqueData = getUniqueObjects();
                        setTableData(uniqueData);
                        setShowSuccessToast(true)
                        setTimeout(() => {
                            setShowSuccessToast(false)
                        }, 2000)
                        setDuplicateDataList([])
                        setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                            "duplicates": true,
                        }))
                        setMessages([...messages, "Great work! You just trimmed the excess. The data looks neater now."])

                    }}>
                        Delete
                    </button>
                </motion.div>
            }
            {
                showSuccessToast && task === "cleanup" && <motion.div
                    className="success-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    Duplicate data Successfully deleted.
                    <img src={TickIcon} />
                </motion.div>
            }

            <UploadCsv open={showLinkCsvModal} handleClose={() => {
                setShowLinkCsvModal(false);
                setMessages([...messages, "Welcome, data explorer! Let’s unlock the treasure chest. Select your dataset, and let’s dive in."])

            }} />

            <DataCleanup
                open={showDataCleanupModal}
                handleClose={() => {
                    setShowActionCreator(true);
                    setShowDataCleanuModal(false);
                    setMessages([...messages, "Hmm, let’s see if we’ve got any pesky duplicates lurking around."])

                }}
            />
            <AnalysisModal
                open={showAnalysis}
                handleClose={() => {
                    setShowActionCreator(true);
                    setShowAnalysis(false);

                    // Add the first message

                    setTimeout(() => {
                        // Add the third message
                        setMessages(prevMessages => [
                            ...prevMessages,
                            problemDetails?.whileBuilding
                        ]);
                    }, 1000);
                }}
            />


            <TreatmentModal
                open={showTreatmentModal}
                handleClose={() => {
                    setShowTreatmentModal(false);
                    setTimeout(() => {
                        setShowMissingDataModal(true)
                    }, 500)
                }}
            />
        </div >
    );
};

export default Gameplay;
