import React, { useState } from 'react';
import AddIcon from "../assets/img/plus.svg"
import EditIcon from "../assets/img/edit.svg"
import TickIcon from "../assets/img/check-circle.svg"

import DeleteIcon from "../assets/img/trash.svg"
import Modal from './Modal';
import './DataTable.css';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import downloadIcon from '../assets/img/download.png';


const DataTable = ({
  handleAddRow,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  handleEditRow,
  handleDeleteRow,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  data,
  showDeleteToast,
  setShowDuplicateDataToast,
  selectedAction,
  setSelectedAction,
  handleCleanupData,
  showActionCreator
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [index, setIndex] = useState(null);
  const [editingRowIndex, setEditingRowIndex] = useState(null);


  const handleOpenModal = (row = {}, index) => {
    setFormData(row, index);
    setIndex(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditClick = (row, rowIndex) => {
    setEditingRowIndex(rowIndex);
    setFormData(row);
  };

  const handleSaveClick = () => {
    handleEditRow(editingRowIndex, formData);
    setEditingRowIndex(null);
    setFormData({});
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (index) {
      handleEditRow(index, formData);
    } else {
      handleAddRow(formData);
    }
    handleCloseModal();
  };

  const downloadCSV = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
  };

  return (
    <div className="data-table-container">
      <div className="data-table-header-container">
        <div className="data-table-header">One-Year Subscription Data</div>

        <div className='data-table-filters'>
          {showActionCreator &&
            <>
              <p>Actions</p>
              <select
                className='filter-input'
                value={selectedAction || "none"}
                onChange={(e) => {
                  setSelectedAction(e.target.value);
                  handleCleanupData(e.target.value);
                }}
              >
                <option value="none" disabled hidden>
                  None
                </option>
                <option value="duplicates">Check For Duplicates</option>
                <option value="missing_data">Missing Data Treatment</option>
              </select>
            </>
          }

          <button className="data-table-button" onClick={() => {
            handleOpenModal();
            setShowDuplicateDataToast(false);
          }}>
            <img src={AddIcon} alt="add" style={{ marginRight: "2px" }} />
            Add Row
          </button>
          <img src={downloadIcon} alt="Download CSV" className="download-icon" onClick={downloadCSV} />
        </div>

      </div>

      <table className="table" {...getTableProps()} style={{
        marginTop: "20px",
        width: "100%",
        borderCollapse: "collapse",
        fontFamily: "Arial, sans-serif",
      }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className="table-header"
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr  {...row.getRowProps()} className={`${editingRowIndex === i ? "data-table-edit-row" : i % 2 === 0 ? 'data-table-row-grey' : 'data-table-row-dark'}`}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {cell.column.id === 'Actions' ? (
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {editingRowIndex === i ? (
                          !showDeleteToast && <img className='edit-save-btn' onClick={handleSaveClick} src={TickIcon} />
                        ) : (
                          !showDeleteToast && showActionCreator && <div className='edit-btn' onClick={() => handleEditClick(row.original, i)}>
                            <img src={EditIcon} />
                          </div>
                        )}
                        {!showDeleteToast && showActionCreator && <div className='delete-btn' onClick={() => handleDeleteRow(i)}>
                          <img src={DeleteIcon} />
                        </div>}
                      </div>
                    ) : (
                      editingRowIndex === i ? (
                        <input
                          className='edit-input'
                          type="text"
                          name={cell.column.id}
                          value={formData[cell.column.id] || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        cell.render('Cell')
                      )
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span style={{ color: "#fff" }}>
          Page{' '}
          <strong style={{ color: "#fff" }}>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span style={{ color: "#fff" }}>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px', background: "black", color: "#fff" }}
          />
        </span>{' '}
        <select
          style={{ background: "black", color: "#fff" }}
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        formData={formData}
        setFormData={setFormData}
      />
    </div >
  );
};

export default DataTable;