import { createSlice } from '@reduxjs/toolkit';
const userSlice = createSlice({
    name: 'user',
    initialState: {
        value: 0,
        coins: 0
    },
    reducers: {
        changeProblem: (state, action) => {
            state.value = action?.payload?.problem
        },
        updateCoinsCount: (state, action) => {
            state.coins = state.coins + action?.payload?.increment
        }
    },
});

export const { changeProblem, updateCoinsCount } = userSlice.actions;
export default userSlice.reducer;