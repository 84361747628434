const basicCharts = [
    {
        name: "Column Chart",
        thumbnail: `https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/datalyze/bar-chart.svg`,
        value: "Grouped Column Chart",
        description: "It displays a categorical dimension and related amounts. Each bar represents a category, width is proportional to the quantitative dimension."
    },
    {
        name: "Stacked Column chart",
        value: "Stacked Column Chart",
        thumbnail: `https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/datalyze/Stacked+bar+chart.svg`,
        description: "It displays multiple quantitative dimensions related to categories. bars are visually stacked according to the categorical dimension, each bar represents a quantitative dimension, mapped on its height."
    },
    {
        name: "Scatter Chart",
        value: "Scatter Chart",
        thumbnail: `https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/datalyze/Bubble+chart.svg`,
        description: "The basic layout is a scatter plot, which allows to see correlations among two continuous dimensions. A further quantitative dimension with size and a quantitative or categorical dimension with color."
    },
    {
        name: "Line chart",
        value: "Line Chart",
        thumbnail: `https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/datalyze/Line+chart.svg`,
        description: "It displays a quantitative dimension over a continuous interval or time period. Colour can be optionally used to encode an additional quantitative or categorical dimension."
    },
    {
        name: "Pie chart",
        value: "Multiple Pie Chart",
        thumbnail: `https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/datalyze/Pie+chart.svg`,
        description: "It allows you to see the proportions between values that make up a whole, by using arcs composing a circle."
    }
]

export default basicCharts;