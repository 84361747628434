import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, onSubmit, formData, setFormData }) => {
  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" >
        <h2 style={{ marginBottom: "10px" }}>{formData.id ? 'Edit Row' : 'Add Row'}</h2>
        <form onSubmit={onSubmit}>
          <label >
            Date:
            <input type="date" name="Date" value={formData.Date || ''} onChange={handleChange} style={{ marginLeft: "10px" }} />
          </label>
          <label>
            Customer Name:
            <input type="text" name="Customer Name" value={formData["Customer Name"] || ''} onChange={handleChange} style={{ marginLeft: "10px" }} />
          </label>
          <label>
            Card Category:
            <input type="text" name="Card Category" value={formData["Card Category"] || ''} onChange={handleChange} style={{ marginLeft: "10px" }} />
          </label>
          <label>
            Subscription Fee:
            <input type="number" name="Subscription Fee" value={formData["Subscription Fee"] || 0} onChange={handleChange} style={{ marginLeft: "10px" }} />
          </label>
          <label>
            Customer Age:
            <input type="number" name="Customer Age" value={formData["Customer Age"] || ''} onChange={handleChange} style={{ marginLeft: "10px" }} />
          </label>
          <label>
            Income:
            <input type="number" name="Income" value={formData.Income || ''} onChange={handleChange} style={{ marginLeft: "10px" }} />
          </label>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <button type="submit" style={{ width: "100px", marginRight: "10px" }}>Submit</button>
            <button type="button" style={{ width: "100px" }} onClick={onClose}>Close</button>
          </div>
        </form>
      </div >
    </div >
  );
};

export default Modal;