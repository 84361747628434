import React, { useEffect, useRef } from 'react';
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import ProblemList from '../data/problems';
import { useSelector } from 'react-redux';
import "./PiovtTableComponent.css"
import { useState } from 'react';

const PivotTableComponent = ({
  tableData,
  setMessages,
  selectedChart
}) => {
  const currentProblem = useSelector((state) => state?.user?.value);
  let problemDetails = ProblemList?.[currentProblem]
  const PlotlyRenderers = createPlotlyRenderers(Plot);
  const [state, setState] = useState({
    data: tableData
  });
  const firstFlagTest = useRef(true);
  const secondFlagTest = useRef(true);
  const [showAnimation, setShowAnimation] = useState(true);
  const activeClass = showAnimation
    ? "custom-pvtAxisContainer"
    : ""

  useEffect(() => {
    setState(Object.assign({}, state, {
      rendererName: selectedChart?.value
    }));
  }, [])
  return (

    <div
      className={activeClass}
      onClick={() => {
        setShowAnimation(false);

      }}
      onDrag={() => {
        setShowAnimation(false);
      }}
      style={{ position: "relative" }}
    >
      <PivotTableUI
        data={state.data}
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        onChange={(s) => {
          if (s?.cols && s?.cols?.length === 1 && firstFlagTest?.current) {
            firstFlagTest.current = null;
            setMessages(prevMessages => [
              ...prevMessages,
              problemDetails?.whileBuilding2
            ]);
          } else if (s?.rows && s?.rows?.length === 1 && secondFlagTest?.current) {
            secondFlagTest.current = null;
            // setMessages(prevMessages => [
            //   ...prevMessages,
            //   problemDetails?.whileBuilding3,
            // ]);
            setTimeout(() => {
              setMessages(prevMessages => [
                ...prevMessages,
                problemDetails?.onSuccess
              ]);
            }, 6000);
            setTimeout(() => {
              setMessages(prevMessages => [
                ...prevMessages,
                `Go ahead and explore more patterns to uncover hidden insights and click on "Proceed" button once you are ready.`,
              ]);
            }, 12000);

          }
          setState(Object.assign({}, s, {
            rendererName: "Table Row Heatmap"
          }));
          setState(s);
        }}
        {...state}
      />
      <div style={{ position: "absolute", top: 100, right: 20 }}>
        <p style={{
          fontSize: "10px",
          color: "#01FC91",
          textShadow: "0 0 1px #01FC91, 0 0 2px #01FC91, 0 0 3px #01FC91"
        }}>
          Column
        </p>
      </div>
      <div style={{ position: "absolute", bottom: 25, left: 20 }}>
        <p style={{
          fontSize: "10px",
          color: "#01FC91",
          textShadow: "0 0 1px #01FC91, 0 0 2px #01FC91, 0 0 3px #01FC91"
        }}>
          Row
        </p>
      </div>
    </div>
  );
};

export default PivotTableComponent;
